import axios from 'axios';

export default class ViaCepService {
  constructor() {
    this.http = axios.create({ baseURL: 'https://viacep.com.br/ws/' });
  }

  findAddressByZipCode(zipCode) {
    return this.http.get(`${zipCode}/json`);
  }
}
