export default {
  name: 'ChangedFieldsMixin',
  methods: {
    async loadChangedFieldsByMovementRecordId(id) {
      if (id) {
        this.overlay = true;
        await this.movementRecordService.GetMovementRecordByIdChangedFields(id).then(async (response) => {
          if (response && response.data) {
            this.changedFields = response.data.changedFields;
          }
          this.overlay = false;
        }).catch(() => {
          this.overlay = false;
        });
      }
    },
    verifyFieldInArray(field) {
      if (this.changedFields && this.changedFields.length > 0) {
        const fieldName = field.split(' ');
        if (fieldName.length === 1) {
          return this.changedFields.some((element) => element === fieldName[0]);
        }
        return this.changedFields.some((element) => element === fieldName[0] || element === fieldName[1]);
      }
      return false;
    },
  },
};
